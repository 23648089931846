<template>
	<div class="content">
		<WithLeftMenu :blocks="withLeftMenuContent"></WithLeftMenu>
		<FeedBack></FeedBack>
	</div>
</template>

<script>
	import	FeedBack from '@/components/app/forms/FeedBack'
	import	WithLeftMenu from '@/components/app/WithLeftMenu'
	import 	projectsData from "@/assets/json/projects.json";

	export default {
		head () {
			return {
				link: [
					{ rel: 'canonical', href: 'https://artrockets.ru' + this.$route.path + '/' }
				]
			}
		},
		metaInfo: {
			title: 'Портфолио веб-студии | «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Выполненные проекты компании Артрокетс. Корпоративные сайты и интернет-магазины, а также онлайн сервисы.' },
				{ vmid: 'og:title', property: 'og:title', content: 'Портфолио веб-студии | «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Выполненные проекты компании Артрокетс. Корпоративные сайты и интернет-магазины, а также онлайн сервисы.' },
			],
		},
		data: () => ({
			withLeftMenuContent: [
				{
					component: 'projects',
					name: 'Портфолио',
					quantity: 8,
					quantityTable: 3,
					quantityMobile: 2,
					showPagination: true,
					data: projectsData
				}
			]
		}),
		name: 'portfolio',
		components: {
			FeedBack,
			WithLeftMenu,
		}
	}
</script>
